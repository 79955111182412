<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Kasa</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Kasa</span>
      </h3>
      <v-col class="text-right p-0">
        <rs-action
          v-if="id && $refs.balanceActivityList && can('edit-safe')"
          @click="handleFirstBalanceFormClick"
        >
          Açılış Fişi Ekle
        </rs-action>

        <rs-action
          :to="{ name: 'definitions.safes.edit', params: { id: id } }"
          v-if="can('edit-safe') && disabled"
          icon
          title="Düzenle"
        >
          <v-icon>mdi-pencil</v-icon>
        </rs-action>

        <rs-action
          @click="
            $refs.confirmDelete.show('Silmek istediğinizden emin misiniz?')
          "
          v-if="
            $refs.confirmDelete &&
            id &&
            transactionCount === 0 &&
            can('delete-safe')
          "
          icon
          title="Sil"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </rs-action>

        <rs-action
          @click="showActionLogs"
          v-if="id && can('edit-safe')"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action>
      </v-col>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <div class="card-body">
        <v-tabs v-model="selectedTab">
          <v-tab to="#info" replace>Bilgiler</v-tab>
          <v-tab
            to="#transactions"
            replace
            :disabled="!id"
            v-if="can('see-balance-activity')"
          >
            <v-badge
              color="deep-purple lighten-3"
              :content="transactionCount || '0'"
            >
              Hesap Hareketleri
            </v-badge>
          </v-tab>
          <v-tab to="#notes" replace :disabled="!id" v-if="can('see-note')">
            <v-badge color="deep-purple lighten-3" :content="noteCount || '0'">
              {{ $t("headers.notes_and_reminders") }}
            </v-badge>
          </v-tab>
          <v-tab
            to="#documents"
            replace
            :disabled="!id"
            v-if="can('see-document')"
          >
            <v-badge
              color="deep-purple lighten-3"
              :content="documentCount || '0'"
            >
              {{ $t("headers.documents") }}
            </v-badge>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="selectedTab">
          <v-tab-item value="info" class="pa-4">
            <v-form @submit.prevent="handleFormSubmit" ref="form">
              <h3 v-if="!id && cluster">
                {{ cluster.name }} için kasa ekliyorsunuz.
              </h3>
              <h3 v-else-if="!id && !cluster">
                Öncelikle üst menüden bir toplu yaşam alanı seçmelisiniz.
              </h3>

              <div v-if="id || cluster">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <rs-text-field
                      :label="$t('labels.safe_short_name')"
                      :rules="[
                        rules.required,
                        rules.maxLength(formData.name, 120),
                      ]"
                      v-model="formData.name"
                      :readonly="disabled"
                      :filled="disabled"
                    />
                  </div>

                  <div class="col-12 col-sm-6">
                    <rs-text-field
                      :label="$t('labels.safe_name')"
                      :rules="[
                        rules.required,
                        rules.maxLength(formData.title, 300),
                      ]"
                      v-model="formData.title"
                      :readonly="disabled"
                      :filled="disabled"
                    />
                  </div>

                  <div class="col-12 col-sm-4">
                    <v-switch
                      v-model="formData.is_active"
                      :label="$t('labels.is_active')"
                      hide-details="auto"
                      :readonly="disabled"
                      :filled="disabled"
                    ></v-switch>
                  </div>

                  <div class="col-12 col-sm-4">
                    <v-switch
                      v-model="formData.can_collect_money"
                      :label="$t('labels.can_collect_money')"
                      hide-details="auto"
                      :readonly="disabled"
                      :filled="disabled"
                    ></v-switch>
                  </div>

                  <div class="col-12 col-sm-4">
                    <v-switch
                      v-model="formData.can_receive_bills"
                      :label="$t('labels.can_receive_bills')"
                      hide-details="auto"
                      :readonly="disabled"
                      :filled="disabled"
                    ></v-switch>
                  </div>

                  <v-col cols="12">
                    <rs-bank-keywords
                      v-model="formData.bank_keywords"
                      :readonly="disabled"
                      :filled="disabled"
                    />
                  </v-col>
                </div>

                <v-row class="mt-5" v-if="id && totals">
                  <v-col cols="12">
                    <h3>Durum</h3>
                  </v-col>
                  <v-col sm="4">
                    <rs-money-field
                      :label="$t('labels.debt')"
                      :value="totals.bank_safe_debt"
                      :disabled="true"
                    />
                  </v-col>

                  <v-col sm="4">
                    <rs-money-field
                      :label="$t('labels.due')"
                      :value="totals.bank_safe_due"
                      :disabled="true"
                    />
                  </v-col>

                  <v-col sm="4">
                    <rs-money-field
                      :label="$t('labels.balance')"
                      :value="balance"
                      :disabled="true"
                    />
                  </v-col>
                </v-row>

                <rs-form-buttons
                  :is-loading="isLoading"
                  @cancel="handleCancelClick"
                  @submit="handleFormSubmit"
                  v-if="!disabled"
                />
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="transactions" class="pa-4" :eager="id">
            <TransactionList
              ref="balanceActivityList"
              :safe-id="id"
              v-if="id && can('see-balance-activity')"
              :item-count.sync="transactionCount"
              :scopes="['safe']"
              :totals.sync="totals"
            />
          </v-tab-item>
          <v-tab-item value="notes" class="pa-4" :eager="id">
            <!-- TODO: the notes should be separate based on "mode" -->
            <NoteList
              :allow-add="true"
              :extra-params="{ safe_id: id }"
              :item-count.sync="noteCount"
              v-if="id && can('see-note')"
              :form-subtitle="originalData.name"
            />
          </v-tab-item>
          <v-tab-item value="documents" class="pa-4" :eager="id">
            <!-- TODO: the notes should be separate based on "mode" -->
            <DocumentList
              :allow-add="true"
              :extra-params="{ safe_id: id }"
              :item-count.sync="documentCount"
              v-if="id && can('see-document')"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <FirstBalanceForm
        ref="firstBalanceForm"
        @saved="$refs.balanceActivityList.loadList()"
      />

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteClick"
        @cancelled="$refs.confirmDelete.hide()"
      />
      <ActionLogList ref="actionLogList" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";
import ActionLogList from "@/view/content/action-logs/ActionLogList";
import FirstBalanceForm from "@/view/content/safes/FirstBalanceForm";
import NoteList from "@/view/content/Notes/NoteList";
import DocumentList from "@/view/content/documents/DocumentList";
import TransactionList from "@/view/content/transactions/TransactionList";

export default {
  name: "SafeForm",
  mixins: [hasForm, hasPermissions],
  components: {
    ActionLogList,
    DocumentList,
    FirstBalanceForm,
    NoteList,
    TransactionList,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
    balance() {
      if (this.totals) {
        const total =
          ((this.totals.bank_safe_debt || 0) * 100 -
            (this.totals.bank_safe_due || 0) * 100) /
          100;

        return total;
      }

      return null;
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      ege: null,
      selectedTab: "info",
      formData: {
        name: null,
        title: null,
        cluster_id: null,
        is_active: true,
        can_receive_bills: false,
        can_collect_money: false,
        bank_keywords: [],
      },
      transactionCount: null,
      totals: null,
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`safes/${this.id}`)
        .then((response) => {
          this.loadData(response);
          this.updateClusterId(this.originalData.cluster_id);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`safes/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$nextTick(() => this.load());
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        formData.cluster_id = this.clusterId;

        this.$api
          .post(`safes`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "definitions.safes.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
    handleDeleteClick() {
      if (this.isLoading) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .delete(`safes/${this.id}`)
        .then(() => {
          this.$toast.success("Silindi");
          this.$router.replace({
            name: "definitions.safes.list",
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFirstBalanceFormClick() {
      this.$refs.firstBalanceForm.show(null, { safe: this.originalData });
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Safe", this.id);
    },
  },
  mounted() {
    if (this.id) {
      this.load();
    }
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
